import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import Script from "next/script";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import { Analytics } from "@vercel/analytics/react";
import "@contentful/live-preview/style.css";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Script
        id="hs-script-loader"
        src="//js.hs-scripts.com/43650738.js"
        strategy="lazyOnload"
      ></Script>
      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={pageProps.draftMode}
        enableLiveUpdates={pageProps.draftMode}
      >
        <Meta title="Home" />
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
        <Analytics />
      </ContentfulLivePreviewProvider>
    </>
  );
}

export default MyApp;
