import Head from "next/head";

const Meta = ({ title, keyword, desc, image, author, publishedTime }) => {
  return (
    <div>
      <Head>
        <title>{`${title} | Blue Quanta LLC`}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        {author && <meta name="author" content={author} />}
        {publishedTime && (
          <meta
            name="publish_date"
            property="og:publish_date"
            content={publishedTime}
          />
        )}
        {image && <meta property="og:image" content={image} />}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Blue Quanta | Design System Specialists",
  keyword:
    "web design, design systems, figma, plug-in, software, business, applications, information systems, integrations, user experience, ux, ui, user interface, react, tailwind, radix-ui, css, cloud applications, saas, paas",
  desc: "The premier design system provider.",
};

export default Meta;
