const footerMenuList = [
  {
    id: 1,
    title: "Company",
    diffClass: "md:col-start-10",
    list: [
      {
        id: 1,
        href: "/",
        text: "Explore",
      },
      {
        id: 2,
        href: "/blog",
        text: "Blog",
      },
      {
        id: 3,
        href: "/about",
        text: "About",
      },
      {
        id: 4,
        href: "/contact",
        text: "Contact Us",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.instagram.com/blue.quanta",
    text: "instagram",
  },
];

export { footerMenuList, socialIcons };
